<template>
    <b-card no-body>
      <b-card-header>
        <b-row>
          <b-col lg="8">
            <h5 class="card-title">Detail {{ $parent.pageTitle }}</h5>
          </b-col>
        </b-row>
      </b-card-header>
      <b-card-body>
        <b-row>
          <b-col md=3>
            <label>Task Item</label>
            <p class="task-item">{{ row.mti_title }}</p>
            <p><strong>{{ row.tta_task_category_name }}</strong></p>
          </b-col>
          <b-col md=3>
            <label>PIC</label>
            <p class="task-item">{{ row.tta_pic_name }}</p>
            <p> <small>Finish By: </small><strong>{{ row.tta_finish_by }}</strong></p>
          </b-col>
          <b-col md=4>
            <label>Date</label>
            <p class="task-item"> Date: <strong>{{ row.tta_start_date }}</strong> s.d. <strong>{{ row.tta_end_date }}</strong></p>
            <p class="task-item"> <small>Finish Date: </small><span class="text-success">{{ row.tta_finish_date | moment('LLL') }}</span></p>
          </b-col>
          <b-col md=3>
            <label>Note</label>
            <p>{{ row.tta_note || '-' }}</p>
          </b-col>
           <b-col md=3>
            <label>Photo</label>
            <silent-box v-if="row.image" :image="{ src: row.image, srcSet: row.image,}">
              <template v-slot:silentbox-item>
                <p>See Photo</p>
              </template>
            </silent-box>
            <p v-else>-</p>
          </b-col>
        </b-row>
        <b-row v-if="row.tte_status != 'pending'">
          <b-col md=6>
            <b-form-group label-for="note">
              <template #label>Status</template>
              <b-badge v-if="row.tte_status == 'approve'" variant="success">Approve</b-badge>
              <b-badge v-else-if="row.tte_status == 'reject'" variant="danger">Reject</b-badge>
            </b-form-group>
            <b-form-group label-for="note">
              <template #label>Note</template>
              <p>{{ row.tte_note }}</p>
            </b-form-group>
          </b-col>
        </b-row>  
      </b-card-body>
      <b-card-footer>
        <b-row>
          <b-col lg="12" class="text-right">
            <button @click="$router.back()" type="button" class="btn btn-rounded btn-default mr-3">{{$parent.isAdd ? 'Cancel' : 'Back'}}</button>
            <template v-if="row.tte_status == 'pending'">
              <b-button v-if="$parent.moduleRole('reject')" @click="modalEvaluation('reject')" type="button" variant="danger" class="btn-rounded mr-3">Reject</b-button>
              <b-button v-if="$parent.moduleRole('approve')" @click="modalEvaluation('approve')" type="button" variant="success" class="btn-rounded">Approve</b-button>
            </template>
          </b-col>
        </b-row>
      </b-card-footer>
      <b-modal 
        id="evaluationTask"
        title="Evaluation Task"
        hide-footer
        no-close-on-backdrop
        :no-enforce-focus="true"
        centered
        >
          <ValidationObserver ref="VFormEvaluation" v-slot="{ handleSubmit }">
            <b-form @submit.prevent="handleSubmit(submitEvaluation)">
              <b-form-group label-for="note">
                <template #label>Status</template>
                <b-badge v-if="dataModal.tte_status == 'approve'" variant="success">Approve</b-badge>
                <b-badge v-else-if="dataModal.tte_status == 'reject'" variant="danger">Reject</b-badge>
              </b-form-group>
              <b-form-group label-for="note">
                <template #label>Task Item</template>
                <p>{{ dataModal.mti_title }}</p>
              </b-form-group>
              <b-form-group label-for="note">
                <template #label>Note <strong class="text-danger">*</strong></template>
                <b-form-textarea id="note"
                  v-model="dataModal.tte_note"
                  :rows="4"
                />
                <VValidate name="Note" v-model="dataModal.tte_note" :rules="mrValidation.tte_note" />
              </b-form-group>
              <b-row class="justify-content-end">
                <b-col md='auto'>
                  <b-button type="button" variant="transparent" @click="doCloseModal('failedTask')">Cancel</b-button>
                  <b-button type="submit" variant="success btn-rounded">Submit</b-button>
                </b-col>
              </b-row> 
            </b-form>
          </ValidationObserver>
      </b-modal>
    </b-card>
</template>
<style scoped>
.task-item {
  margin: 0;
}
</style>
<script>
  import Gen from '@/libs/Gen.js'

 export default {
    props: {
      row: Object,
      mrValidation: Object,
    },
    data(){
      return {
        dataModal: {}
      }
    },
    mounted(){
      this.updateTime()
    },
    methods: {
      doCloseModal(){
        this.dataModal = {}
        this.$bvModal.hide('evaluationTask')
      },
      modalEvaluation(status){
        this.dataModal = Object.assign({}, {...this.row})
        this.dataModal.tte_status = status
        this.$bvModal.show('evaluationTask')
      },
      submitEvaluation(){
        this.$refs['VFormEvaluation'].validate().then(success => {
          if(!success) return

          this.$swal({
            title: 'Loading...',
            showConfirmButton: false,
            allowOutsideClick: false,
            didOpen: () => {
              this.$swal.showLoading()
            }
          })

          Gen.apiRest('/do/' + this.$parent.modulePage, { data: Object.assign({type: 'submit-evaluation' },  {...this.dataModal}) }, "POST").then(res => {
            this.$swal({
              title: res.data.message,
              icon: 'success',
              confirmButtonText: 'Oke'
            }).then(()=> {
              this.$bvModal.hide('evaluationTask')
              this.$router.push({name: this.$parent.modulePage, params: {}})
            })
          }).catch(err=> {
            this.loadingOverlay = false 
            if(err){
                err.statusType = err.status
                err.status = "error"
                err.message = err.response.data.message
                err.title = err.response.data.title
                err.messageError = err.message
            }
            this.$parent.doSetAlertForm(err)
          })
        })
      }
    }
 }
</script>
